@mixin d-flex {
  display: flex;
}

@mixin flex-center {
  @include d-flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-end {
  @include d-flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-start {
  @include d-flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-between {
  @include d-flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-around {
  @include d-flex;
  justify-content: space-around;
  align-items: center;
}

@mixin flex-wrap {
  flex-wrap: wrap;
}

@mixin flex-col {
  flex-direction: column;
}

@mixin flex-row {
  flex-direction: row;
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block !important;
}
