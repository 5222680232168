@import "src/assets/styles/global-style.scss";

button:disabled {
  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;

    mat-spinner {
      margin-inline-end: 8px;
    }
  }
}

button {
  transition: all $global-transition !important;
  border-radius: $btn-brd-radius !important;

  mat-icon {
    transition: color $global-transition;
  }
}

// .fs-btn-txt,
// .fc-button {
//   font-style: normal !important;
//   font-weight: $button-fnt-weight !important;
//   font-size: $button-fnt-size !important;
//   //line-height: $button-line-height !important;
//   text-transform: uppercase !important;
// }

.prm-btn {
  color: $white-color !important;
  background-color: $prm-color !important;
}

.prm-btn:focus-within,
.prm-btn:hover {
  color: $white-color;
  background-color: $prm-hover-color !important;
}

.prm-btn:disabled {
  color: white !important;
  background-color: $prm-dis-color !important;
}

.dng-btn {
  color: $white-color;
  background-color: $dng-color !important;
}

.dng-btn:focus-within {
  color: $white-color;
  background-color: $dng-color !important;
}

.dng-btn:disabled {
  color: $dis-clr-button !important;
  background-color: $dng-dis-color !important;
}

mat-checkbox {
  color: $white-color;

  label {
    color: $white-color !important;

    .mat-mdc-checkbox-frame {
      border-color: $prm-color !important;
    }
  }
}

.mat-mdc-checkbox-indeterminate.mat-primary .mat-mdc-checkbox-background,
.mat-mdc-checkbox-checked.mat-primary .mat-mdc-checkbox-background {
  background-color: $prm-color;
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    background-color: $level3-gray !important;

    .div-sep {
      margin-inline: auto;
    }

    button {
      min-width: unset !important;
      min-height: unset !important;
      padding: 0 16px !important;
      color: white !important;

      span {
        color: inherit !important;
      }
    }

    button:disabled {
      color: rgb(219 219 219) !important;

      mat-icon,
      span {
        color: inherit !important;
      }
    }
  }
}

mat-form-field {
  display: block;
  margin-bottom: 20px !important;

  .input-action-btn {
    position: absolute;
    right: -6px;
    top: 13px;
    padding: 0px;
    width: fit-content !important;
    min-width: unset !important;
    transform: scale(0.8);
    z-index: 1;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper {
    position: relative;
    background-color: $input-bg !important;
    border-radius: $form-field-brd-radius !important;
    transition: $global-transition border-color;
    border: 1px solid $form-field-brd-color !important;

    .mat-mdc-form-field-underline {
      background-color: none !important;
    }

    .mat-mdc-form-field-outline:hover {
      border-bottom: $prm-color !important;
    }

    label {
      color: $input-color-label !important;
      font-size: 16px !important;
    }

    input,
    textarea,
    .mat-mdc-select-value-text {
      color: $input-color-txt !important;
    }

    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      border-bottom: unset !important;
    }
  }

  .mat-mdc-text-field-wrapper:hover {
    border-color: $input-hover-color !important;
  }

  .mat-mdc-text-field-wrapper:focus-within {
    border-color: $prm-color !important;
  }

  mat-select {
    .mat-mdc-select-arrow {
      color: $white-color;
    }
  }

  .option-btn {
    position: absolute;
    top: -24px;
    left: -60px;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-mdc-form-field-flex {
    .mat-mdc-icon-button {
      color: $input-color-label !important;
      padding: 0 !important;
    }

    mat-datepicker-toggle {
      .mat-mdc-icon-button {
        padding-inline-end: 14px !important;
      }
    }
  }
}

.button-wrapper {
  input {
    display: none;
  }

  .mat-mdc-form-field-flex {
    height: 56px !important;
  }

  .button-g {
    position: absolute;
    top: 12px;
    right: -10px;
    width: 180px;

    button {
      width: 44% !important;
      padding: 0 !important;
      height: 35px !important;
      margin-inline-start: 5px !important;
      min-width: unset !important;
      margin-top: -2px;
      font-size: 18px;
    }
  }

  .btn-3 {
    button {
      width: 54px;
    }
  }

  .btn-2 {
    button {
      width: 80px;
    }
  }
}

mat-accordion {
  width: 100% !important;

  mat-expansion-panel {
    background-color: $input-bg !important;

    mat-expansion-panel-header {
      .mat-mdc-content {
        mat-panel-title {
          color: $white-color !important;
        }

        mat-panel-description {
          color: $card-txt-color !important;
        }
      }

      .mat-mdc-expansion-indicator::after {
        color: $white-color !important;
      }
    }
  }
}

.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
  background-color: $level3-gray !important;

  mat-option {
    span {
      color: $input-color-txt !important;
      font-size: 18px !important;
    }
  }

  .mat-mdc-optgroup-label span {
    color: $input-color-label !important;
  }
}

.mat-mdc-form-field-ripple {
  background-color: transparent !important;
}

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-underline::before {
  background-color: transparent !important;
}

mat-spinner {
  svg {
    circle {
      stroke: $light-gray !important;
    }
  }
}

mat-icon,
mat-panel-title {
  color: $white-color !important;
}

mat-panel-description {
  color: $light-gray !important;
}

.mat-mdc-snack-bar-action {
  color: yellow !important;
}

// .not-checked,
// .not-checked span {
//   color: $level1-red !important;
// }

// .checked,
// .checked span {
//   color: $level1-green !important;
// }

// mat-paginator {
//   background-color: $level3-gray !important;
//   color: $light-gray !important;
// }

// .mat-paginator-range-actions {
//   button {
//     background-color: transparent !important;
//   }
// }

mat-list-item {
  span {
    color: $light-gray;
  }
}

mat-divider {
  background-color: $level2-gray !important;
}

.mat-mdc-menu-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: fit-content;

  .div-sep {
    width: 92% !important;
  }

  .fs-option-item {
    width: 100%;
  }

  .mat-mdc-menu-item {
    height: 2rem !important;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    min-width: 10rem !important;

    mat-icon {
      font-size: 1rem !important;
      height: fit-content !important;
      width: fit-content !important;
      margin: 0 !important;
    }

    span {
      font-size: 0.8rem !important;
      margin-inline-start: 8px !important;
    }
  }
}

.fs-card {
  background-color: $card-bg-color;
  color: $card-txt-color;
  position: relative;
  border-radius: 4px;
  margin-bottom: 1.6rem;
  border: 1px solid $form-field-brd-color;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  .fs-card-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    img {
      position: inherit;
      top: 0;
      left: 0;
      width: 100%;
      height: 30px;
      object-fit: cover;
      object-position: center;
    }

    span {
      font-size: 20px;
      padding: 0 6px;
      margin-top: 4px;
      display: block;
    }
  }

  p {
    color: inherit;
    font-size: 17px;
    line-height: 21px;
    font-weight: 400;
    font-style: normal;
    margin: 1rem;
    text-align: center;
  }

  .fs-citem-list {
    width: 100%;
    padding: 0 12px;
    padding-top: 2rem;

    p {
      text-align: start;
      font-size: inherit;
      width: max-content;
    }

    button {
      min-width: fit-content;
      padding: 0;
      margin-inline: 6px;
    }

    mat-list {
      overflow-y: auto;
      overflow-x: hidden;
      height: 314px !important;
      width: 94%;

      mat-list-item {
        cursor: pointer;
        height: fit-content;
        padding: 0;
        margin-bottom: 10px;
        padding-bottom: 10px;

        h6,
        span {
          font-size: 20px;
          color: $card-txt-color;
        }

        > div {
          justify-content: space-between !important;
        }

        .event-name {
          display: block;
          max-width: 100%;
          word-wrap: break-word;
        }

        .card-actions {
          width: max-content;
          display: flex;
          justify-content: center;
          flex-direction: column;

          button {
            width: 36px;
            height: 20px;

            span {
              font-size: 13px;
            }
          }

          button:first-child {
            margin-bottom: 8px;
          }

          .no-btn {
            background-color: #e20000;
          }

          .yes-btn {
            background-color: #009840;
          }
        }
      }

      mat-list-item:not(:last-child) {
        border-bottom: 1px solid #828282;
      }
    }
  }
}

.fs-view-card {
  color: $card-txt-color;
  background-color: $scard-bg-color;
  border: 1px solid rgba(133, 133, 133, 0.8);
  //padding: 6px 8px;
  position: relative;
  border-radius: 4px;
  //min-height: 178px;
  width: 292px;
  margin: 15px;
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 15px;
  }

  .sim-led {
    width: 16px;
    height: 16px;
    display: block;
    background-color: #c5c5c5;
    border-radius: 50%;
    margin-inline-end: 8px;
    margin-bottom: -4px;
  }

  .menu-wrapper {
    position: absolute;
    top: 0;
    right: 0;

    mat-icon {
      color: $scard-txt-color;
    }
  }

  h3 {
    padding: 4px 10px;
    margin-bottom: 8px;
    color: $white-color;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
  }

  p {
    padding: 0 10px;
    margin-bottom: 8px;
    color: $scard-txt-color;
  }

  .div-sep {
    margin: 1.2rem auto;
    width: 96% !important;
  }

  // span {
  //   font-weight: 500;
  //   font-size: 0.8rem;
  // }
}

.sim-led-active {
  background-color: lime !important;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: #2aff3d 0 0 0 0;
  }
  100% {
    box-shadow: #2aff3d00 0 0 0 8px;
  }
}

.fs-control-container {
  @extend .fs-card;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 56px;
  width: 94%;
  padding: 0;
  margin: 0 8px;
  margin-bottom: 20px;
}

/*-------Table style---------*/
.mat-elevation-z8 {
  min-width: 1200px;
  background-color: transparent !important;
  box-shadow: none !important;

  table {
    width: 100%;
    min-width: inherit;
    background: none !important;

    thead {
      background-color: transparent !important;
      position: sticky;
      top: 0;
      z-index: 2;
    }

    .mat-row {
      height: 40px !important;
    }

    th {
      color: $th-title-color !important;
      background-color: $th-backcolor !important;
      border-bottom: none !important;
      border: $th-border-size solid $th-border-color !important;
      border-right-color: transparent !important;
      padding: $th-padding !important;
      height: $th-height !important;
      font-size: $th-title-fsize !important;
    }

    th:first-child {
      width: 340px !important;

      img {
        margin-inline: 8px !important;
      }
    }

    th:last-child {
      width: 432px !important;
    }

    th:nth-last-child(2) {
      border-right-color: $th-border-color !important;
    }

    .th-remove {
      display: none !important;
    }

    tr {
      background-color: $level5-gray !important;

      .mat-sort-header-container {
        display: flex;
        justify-content: center;
      }

      td {
        font-size: $td-font-size !important;
        font-weight: $td-font-weight !important;
        color: $white-color !important;
        background-color: $td-backcolor !important;
        border: $td-border-size solid $td-border-color !important;
        border-bottom-color: transparent !important;
        border-right-color: transparent !important;
        padding: 0 8px !important;
        width: 405px !important;

        .update-row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .text-content {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding-top: 4px;

          i {
            padding-inline-start: 8px !important;
          }
        }

        .clickable {
          cursor: pointer;
        }
      }

      .actions {
        mat-icon {
          color: inherit;
        }

        button:disabled {
          background-color: transparent !important;
        }
      }
    }

    tr:first-child > td {
      border-top: unset !important;
    }

    .td-remove {
      width: 40px !important;
      background-image: url(../../assets/images/table-remove-border.png);
      background-position: left;
      background-size: 100% 101%;
      background-repeat: no-repeat;
      padding: 0 !important;
      border: unset !important;

      .text-content {
        justify-content: center;

        img {
          cursor: pointer;
          padding-inline-start: 0px !important;
        }
      }
    }

    .h-start {
      .mat-sort-header-container {
        display: flex;
        justify-content: flex-start !important;
      }
    }
  }

  mat-paginator {
    font-size: 16px !important;
    margin-inline-end: 2.5rem !important;
    background-image: url(../../assets/images/pagein-border.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100% 100% !important;
    width: calc(100% - 40px) !important;

    .mat-mdc-paginator-range-actions {
      .mat-mdc-paginator-range-label {
        margin-inline: 4px !important;
      }
    }

    .mat-mdc-paginator-page-size {
      @extend .flex-center;

      .mat-mdc-paginator-page-size-label {
        display: none !important;
      }
    }

    mat-form-field {
      width: fit-content !important;
      margin-bottom: 0px !important;

      .mat-mdc-text-field-wrapper {
        width: 55px !important;
        height: 40px !important;
        padding: 0px !important;

        .mat-mdc-form-field-flex {
          @extend .flex-center;

          background-color: $pagein-selector-backcolor !important;
          border: $pagein-selector-border-size solid
            $pagein-selector-border-color !important;
          width: 100%;
          height: 100%;
        }

        .mat-mdc-form-field-infix {
          padding: 0 !important;
          border: none !important;

          mat-select {
            margin-top: 6px !important;
          }
        }
      }

      .mat-mdc-form-field-underline {
        height: 0 !important;
      }
    }
  }
}

.table-controls {
  @extend .flex-center;
  align-content: stretch;

  margin-top: 50px;
  margin-bottom: 18px;

  button {
    width: fit-content !important;
    height: 58px !important;
    border-radius: 4px !important;
    padding: 12px 24px 12px 24px !important;
    gap: 10px !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 21px !important;
    line-height: 26px !important;
    text-transform: uppercase !important;
    //margin-inline-start: 20px !important;
  }

  mat-form-field {
    width: 344px !important;
    min-height: 50px !important;
    margin-bottom: 0 !important;
    color: #bbbcbf !important;

    img {
      margin-inline-end: 8px;
    }

    // .mat-mdc-form-field-flex {
    //   padding: 4px !important;
    //   padding-inline-start: 16px !important;
    // }

    mat-label {
      @extend .flex-start;

      font-size: 20px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      color: #bbbcbf !important;

      mat-icon {
        margin-inline-end: 8px !important;
        font-size: 26px !important;
      }
    }
  }
}
/*------------------------------*/

.page-section {
  @include flex-center;

  align-items: flex-start;
  //min-width: 1340px;

  h4 {
    margin-bottom: 16px !important;
    color: #ffffff !important;
    font-weight: 700 !important;
    font-size: 42px !important;
    line-height: 52px !important;
    text-transform: uppercase !important;
    width: 100%;
  }
}

.not-checked,
.not-checked span {
  color: $level1-red !important;
}

.checked,
.checked span {
  color: $level1-green !important;
}

mat-paginator {
  background-color: transparent !important;
  color: $light-gray !important;

  .mat-mdc-paginator-range-actions {
    button {
      background-color: transparent !important;
      color: $white-color !important;
    }

    button:disabled {
      color: $prm-dis-clr-button !important;
    }
  }

  mat-form-field {
    .mat-mdc-form-field-flex {
      border: unset !important;
      padding: 0 6px !important;
    }
  }
}

.fs-card-mh {
  height: 362px;
  width: 360px;

  mat-list-item {
    h6,
    .event-name {
      max-width: 220px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

mat-bottom-sheet-container {
  background-color: $bottom-sheet-bg !important;
  max-height: 40vh !important;
  min-height: 137px !important;
  min-width: 606px !important;
  position: relative;
  border: $prm-color 1px solid !important;
  border-bottom: none !important;
  border-radius: 0px !important;

  mat-list {
    padding-top: 30px !important;
  }

  .mat-mdc-list-item-content {
    color: $white-color !important;
  }

  button {
    color: $card-txt-color !important;
  }

  .header-bottonsheet {
    position: fixed;
    top: -1px;
    left: -1px;
    z-index: 9;
    width: 100%;
    height: 32px;

    h3 {
      position: absolute;
      font-size: 20px;
      font-weight: 700;
      left: 10px;
      top: 0;
      color: white;
      height: 25px;
      margin: 0px;
    }

    .close-btn {
      top: 5px !important;
      min-width: 0px !important;
      padding: 0 !important;
      position: fixed;
      right: 10px;
      top: 10px;
    }
  }
}

.loader-btn {
  mat-spinner svg circle {
    stroke: $prm-dis-clr-button !important;
  }
}

mat-toolbar {
  background-color: $prm-color !important;
}

.timepicker {
  .timepicker-dial {
    input {
      font-weight: bold !important;
    }

    .timepicker-period {
      padding-bottom: 10px !important;

      button {
        padding: 0 !important;
        height: 22px !important;
        min-height: unset !important;
      }
    }
  }

  .timepicker__body .clock-face {
    background-color: $white-color !important;

    .clock-face__number {
      .mat-mdc-mini-fab {
        background-color: $prm-color;
      }

      button {
        .mdc-button__label {
          color: black !important;
        }
      }
    }

    [mat-mini-fab].mat-void {
      background-color: unset;
    }

    button {
      border-radius: 50% !important;

      .mat-mdc-button-wrapper {
        color: $snav-bg-color !important;
        font-weight: bold;
      }
    }
  }
}

ngx-mat-timepicker-dialog {
  .mat-mdc-dialog-actions div:nth-child(1) {
    button {
      background-color: $dng-color !important;
    }
  }

  .mat-mdc-dialog-actions div:nth-child(2) {
    button {
      background-color: $prm-color !important;
    }
  }

  .mat-mdc-dialog-actions button {
    width: 88px !important;
    margin-inline-start: 8px !important;
  }
}

.ngx-mat-mdc-timepicker-dialog {
  mat-dialog-container {
    padding: 24px !important;
    padding-top: 0 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $input-bg inset !important;
  -webkit-text-fill-color: $input-color-txt !important;
}

::placeholder {
  color: $input-color-label !important;
}

::-ms-input-placeholder {
  color: $input-color-label !important;
}

.mat-mdc-button-focus-overlay {
  background-color: transparent !important;
}

.description-warning {
  padding: 0 2.4rem;
  display: block;

  p {
    margin: 0;
    margin-top: -6px;
    text-align: end;
    float: right;
    width: fit-content;
    font-size: 15px;
  }

  p span:nth-child(1) {
    color: white;
    font-size: 15px;
  }
}

.description-warning > span {
  color: red;
  float: left;
  margin-top: -6px;
  font-size: 15px;
}

.header-toolbar {
  mat-form-field {
    width: 324px !important;

    .mat-mdc-select-min-line {
      font-size: 20px !important;
      font-weight: 400 !important;
    }
  }
}

.mat-mdc-select-min-line {
  color: $level2-white;
}

mat-slide-toggle {
  .mat-mdc-slide-toggle-bar {
    width: 36px;
    height: 24px;
    border-radius: 14px;
    background-color: #828282;
  }

  .mat-mdc-slide-toggle-thumb-container {
    top: 2px;

    .mat-mdc-slide-toggle-thumb {
      width: 20px;
      height: 20px;
      background-color: white !important;
    }
  }
}

.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $prm-color !important;
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.i-table-h {
  width: 25px;
  height: 25px;
}

.half-field-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding-inline-start: 21px;
}

.replace-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76px;
  width: 100%;

  p {
    margin: 0;
  }
}

.mat-mdc-paginator-icon {
  fill: unset !important;
}

.mat-mdc-form-field-infix {
  min-width: unset !important;
}

ngx-mat-timepicker-toggle > button {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-inline-start: 0 !important;

  svg {
    width: 30px !important;
    height: 30px !important;
  }
}

.alert-text {
  text-align: center;
  width: auto;
  padding: 1rem !important;
}

@media (max-width: $medium-screen) {
  mat-card {
    width: 70%;
  }

  mat-bottom-sheet-container {
    min-width: unset !important;
    width: 100vw !important;
    min-height: 16vh !important;
    max-height: 90vh !important;
  }

  .fs-view-card {
    width: 100%;
  }

  .page-section {
    @include flex-col;

    padding-bottom: 6rem;
    padding-top: 5rem;
  }

  .mat-mdc-menu-panel {
    .mat-mdc-menu-content {
      .fs-option-item {
        margin-bottom: 0.7rem;
      }

      button {
        width: max-content !important;

        mat-icon,
        span {
          font-size: 1.4rem !important;
        }
      }
    }
  }
}

@media (min-width: $medium-screen) {
  .page-section {
    @include flex-row;

    width: fit-content;
  }
}

.fs-list {
  margin-bottom: 2rem;

  mat-list-item {
    position: relative;
  }

  .fs-list-r,
  .fs-list-h {
    height: 47px;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .fs-list-h {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $th-backcolor;
    border: 1px solid $th-border-color;
    font-size: 18px;
    color: $th-title-color;

    & > div:not(:last-child) {
      border-inline-end: 1px solid $th-border-color;
    }
  }

  .fs-list-r {
    font-size: 20px;
    border: 1px solid $td-border-color;
  }

  .fs-list-d-warpper {
    display: flex;

    mat-list {
      width: 100%;
      padding: 0;

      &:not(:last-child) .fs-list-r {
        border-inline-end: none;
      }
    }

    .draggble .fs-list-r {
      cursor: move;
    }
  }

  mat-list-item {
    padding: 0;
    overflow: initial;

    .fs-list-r {
      span {
        color: white;
      }
    }
  }
}

mat-list-item.cdk-drag-preview {
  height: 47px;
  background-color: $th-backcolor;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  overflow: initial !important;
  .fs-list-r {
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: white;
    }
  }
}

mat-list-item.cdk-drag-disabled {
  span {
    color: $fs-yellow !important;
  }
}

mat-list-item.cdk-drag-placeholder {
  opacity: 0;
}

mat-list-item.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.draggble.cdk-drop-list-dragging mat-list-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

mat-button-toggle-group {
  border: none !important;

  mat-button-toggle {
    background-color: transparent !important;
    border: none !important;
  }
}

.mat-button-toggle-checked {
  background-color: #2c4282 !important;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: $small-screen) {
  mat-card {
    width: 70%;
  }
}
